import { Container, Typography, Box, Paper } from '@mui/material';
import { Separator } from './CommonComponents';
import { useScrollToTop } from '../utils/hook';
import { useTranslation } from 'react-i18next';

const CookiePolicyPage = () => {
  useScrollToTop();
  const { t } = useTranslation();

  return (
    <>
      <Separator />
      <Container maxWidth="md" sx={{ mt: 5, padding: 10 }}>
        <Paper elevation={4} sx={{ p: 6, mt: 3 }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              {t('cookiePolicyData.title')}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>{t('cookiePolicyData.greeting')}</strong>
              <br />
              {t('cookiePolicyData.intro')}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>{t('cookiePolicyData.whatAreCookies.title')}</strong>
              <br />
              {t('cookiePolicyData.whatAreCookies.content1')}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('cookiePolicyData.whatAreCookies.content2')}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>{t('cookiePolicyData.cookieTypes.title')}</strong>
              <br />
              <strong>
                {t('cookiePolicyData.cookieTypes.technical.title')}
              </strong>{' '}
              {t('cookiePolicyData.cookieTypes.technical.content')}
              <ul>
                <li>
                  <strong>
                    {t(
                      'cookiePolicyData.cookieTypes.technical.navigationOrSession.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.technical.navigationOrSession.content'
                  )}
                </li>
                <li>
                  <strong>
                    {t(
                      'cookiePolicyData.cookieTypes.technical.analytics.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.technical.analytics.content'
                  )}
                </li>
                <li>
                  <strong>
                    {t(
                      'cookiePolicyData.cookieTypes.technical.functionality.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.technical.functionality.content'
                  )}
                </li>
              </ul>
              {t('cookiePolicyData.cookieTypes.technical.installationNotice')}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>
                {t('cookiePolicyData.cookieTypes.profiling.title')}
              </strong>
              {t('cookiePolicyData.cookieTypes.profiling.content')}
              <ul>
                <li>
                  <strong>
                    {t(
                      'cookiePolicyData.cookieTypes.profiling.advertising.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.profiling.advertising.content'
                  )}
                </li>
                <li>
                  <strong>
                    {t(
                      'cookiePolicyData.cookieTypes.profiling.behavioral.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.profiling.behavioral.content'
                  )}
                </li>
              </ul>
              {t('cookiePolicyData.cookieTypes.profiling.installationNotice')}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>
                {t('cookiePolicyData.cookieTypes.cookieSubjects.title')}
              </strong>
              <ul>
                <li>
                  <strong>
                    {t(
                      'cookiePolicyData.cookieTypes.cookieSubjects.siteManagerCookies.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.cookieSubjects.siteManagerCookies.content'
                  )}
                </li>
                <li>
                  <strong>
                    {' '}
                    {t(
                      'cookiePolicyData.cookieTypes.cookieSubjects.thirdPartyCookies.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.cookieSubjects.thirdPartyCookies.content'
                  )}
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>
                {' '}
                {t('cookiePolicyData.cookieTypes.cookieDuration.title')}
              </strong>
              <ul>
                <li>
                  <strong>
                    {' '}
                    {t(
                      'cookiePolicyData.cookieTypes.cookieDuration.sessionCookies.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.cookieDuration.sessionCookies.content'
                  )}
                </li>
                <li>
                  <strong>
                    {t(
                      'cookiePolicyData.cookieTypes.cookieDuration.persistentCookies.title'
                    )}
                  </strong>{' '}
                  {t(
                    'cookiePolicyData.cookieTypes.cookieDuration.persistentCookies.content'
                  )}
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>{t('cookiePolicyData.userNotification.title')}</strong>
              <br />
              {t('cookiePolicyData.userNotification.content')}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>{t('cookiePolicyData.browserInstructions.title')}</strong>
              <br />
              {t('cookiePolicyData.browserInstructions.content')}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('cookiePolicyData.browserInstructions.content2')}
              <ul>
                <li>
                  <a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d#:~:text=and%20login%20information.-,Open%20Edge%20browser%2C%20select%20Settings%20and%20more%20in%20the%20upper,recommended)%20to%20allow%20all%20cookies.">
                    Microsoft Edge
                  </a>
                </li>
                <li>
                  <a href="https://support.google.com/chrome/bin/answer.py?hl=es&answer=95647&p=cpn_cookies">
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a href="http://support.mozilla.org/is-ES/kb/Enabling%20and%20disabling%20cookies">
                    Mozilla Firefox
                  </a>
                </li>
                <li>
                  <a href="http://support.apple.com/kb/PH17191?viewlocale=es_ES&locale=es_ES">
                    Safari
                  </a>
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>
                {' '}
                {t('cookiePolicyData.thirdPartyCookiesBlocking.title')}
              </strong>
              <br />
              {t('cookiePolicyData.thirdPartyCookiesBlocking.content')}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong> {t('cookiePolicyData.doNotTrackOption.title')}</strong>
              <br />
              {t('cookiePolicyData.doNotTrackOption.content')}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>{t('cookiePolicyData.incognitoMode.title')}</strong>
              <br />
              {t('cookiePolicyData.incognitoMode.content')}
              <br />
              {t('cookiePolicyData.incognitoMode.content2')}
            </Typography>

            <Box sx={{ mt: 4 }}>
              <strong>{t('cookiePolicyData.cookiesTitle')}</strong>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginTop: '20px',
                }}
              >
                <thead>
                  <tr>
                    {[
                      'Nombre del cookie',
                      'Descripción del cookie',
                      'Tipo',
                      'Gestión',
                      'Duración',
                    ].map((header) => (
                      <th
                        key={header}
                        style={{
                          border: '1px solid #ddd',
                          padding: '8px',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      name: '_tccl_visitor',
                      description:
                        'Recopila datos agregados y anonimizados sobre el rendimiento del sitio.',
                      type: 'Análisis',
                      management: 'GoDaddy',
                      duration: '1 año',
                    },
                    {
                      name: '_tccl_visit',
                      description:
                        'Recopila datos anonimizados para mejorar el rendimiento del sitio.',
                      type: 'Análisis',
                      management: 'GoDaddy',
                      duration: '1 hora',
                    },
                    {
                      name: '_scc_session',
                      description: 'Gestiona la sesión del usuario.',
                      type: 'Otro',
                      management: 'Interno',
                      duration: '20 min',
                    },
                  ].map((cookie) => (
                    <tr key={cookie.name}>
                      {Object.values(cookie).map((value, index) => (
                        <td
                          key={index}
                          style={{ border: '1px solid #ddd', padding: '8px' }}
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <br />
            <Typography variant="body1" paragraph>
              <strong>
                {t('cookiePolicyData.thirdPartyCookiesInfo.title')}
              </strong>
              <br />
              {t('cookiePolicyData.thirdPartyCookiesInfo.content')}{' '}
              <a href="http://www.youronlinechoices.co.uk">
                www.youronlinechoices.co.uk
              </a>
              .
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>{t('cookiePolicyData.contactData.title')}</strong>
              <br />
              {t('cookiePolicyData.contactData.content')}{' '}
              <a href="mailto:admin@cloverlavanderia.es">
                {t('cookiePolicyData.contactData.email')}
              </a>
              .
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default CookiePolicyPage;
