import { Typography } from '@mui/material';
import { palette, sectionStyle } from '../utils/styleguide';
import Grid from '@mui/material/Grid2';
import {
  InstagramLink,
  LocationLink,
  TelephoneIcon,
} from '../components/ContactUsIcons';
import { t } from 'i18next';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../components/ContactForm';
import { useCloverNavigator } from '../utils/hook';

const BookNowWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Grid
      id="contact"
      container
      direction="column"
      sx={{
        backgroundColor: palette.darkWhite,
        justifyContent: 'center',
        alignItems: 'center',
        color: palette.darkBlue,
        padding: { xs: 5, md: 10 },
        textAlign: 'center',
      }}
      spacing={2}
    >
      {children}
    </Grid>
  );
};

const BookNow = () => {
  const { t } = useTranslation();

  return (
    <BookNowWrapper>
      <Grid>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {t('booking.allDaysOpen')}
        </Typography>

        <Typography variant="h6">{t('booking.schedule')}</Typography>
      </Grid>
    </BookNowWrapper>
  );
};

const ContactWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Grid
      container
      sx={{
        ...sectionStyle,
        backgroundColor: palette.darkWhite,
        paddingLeft: { xs: 10, sm: 10, md: 15, lg: 30, xl: 40 },
        paddingRight: { xs: 10, sm: 10, md: 15, lg: 30, xl: 50 },
        paddingBottom: { xs: 5, sm: 5, md: 5, lg: 10, xl: 10 },
        color: palette.darkBlue,
      }}
      spacing={2}
      flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
    >
      {children}
    </Grid>
  );
};

const ContactInfoSection = ({ children }: { children: ReactNode }) => {
  return (
    <Grid
      size={{ xs: 12, sm: 4, md: 4 }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%', width: '100%' }}
    >
      {children}
    </Grid>
  );
};

const ContactSection = () => {
  const { isHomePage } = useCloverNavigator();
  const showContactForm = isHomePage;
  const { t } = useTranslation();

  return (
    <>
      <BookNow />

      {showContactForm && (
        <ContactInfoSection>
          <ContactForm />
        </ContactInfoSection>
      )}

      <ContactWrapper>
        <ContactInfoSection>
          <Typography variant="h5" fontWeight="bold">
            <LocationLink />
          </Typography>
          <Typography variant="body1">
            {t('contact.street')} <br />
            {t('contact.city')} <br />
            {t('contact.country')}
          </Typography>
        </ContactInfoSection>

        <ContactInfoSection>
          <TelephoneIcon />
          <Typography variant="body1" display="flex" alignItems="center">
            679183603
          </Typography>
          <Typography variant="body1" display="flex" alignItems="center">
            683556773
          </Typography>
        </ContactInfoSection>

        <ContactInfoSection>
          <InstagramLink />

          <a
            href="https://www.instagram.com/cloverlaundryalicante"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <Typography variant="body1" display="flex" alignItems="center">
              Clover Instagram
            </Typography>
          </a>
        </ContactInfoSection>
      </ContactWrapper>
      <div style={{ marginTop: 'auto', backgroundColor: palette.darkBlue }}>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            color: palette.white,
            padding: 2,
            fontSize: '1rem',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          © {new Date().getFullYear()} {t('footer.text')} |
          <a
            href="/cookie-policy"
            style={{
              color: palette.white,
              textDecoration: 'underline',
              marginLeft: 5,
            }}
          >
            {t('footer.cookiesPolicy')}
          </a>{' '}
          |
          <a
            href="/privacy-policy"
            style={{
              color: palette.white,
              textDecoration: 'underline',
              marginLeft: 5,
            }}
          >
            {t('footer.privacyPolicy')}
          </a>
        </Typography>
      </div>
    </>
  );
};

export default ContactSection;
