import { Typography } from '@mui/material';
import { StyledCloverFixedLogo } from '../styles/styles';
import { palette } from '../utils/styleguide';
import { useCloverNavigator } from '../utils/hook';

export const CloverNavbarLogo = () => {
  const { navigateHomePage, isHomePage } = useCloverNavigator();

  return (
    <div
      style={{
        display: isHomePage ? 'none' : 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 'auto',
      }}
    >
      <Typography
        variant="h1"
        fontWeight={800}
        sx={{ color: palette.white, pointer: 'cursor' }}
        onClick={() => navigateHomePage()}
      >
        <StyledCloverFixedLogo
          key={'logoCloverNavbar'}
          src="/logoClover.svg"
          alt="Clover"
        />
      </Typography>
    </div>
  );
};
