export const PAGES_NAMES = ['recommendations', 'faq', 'services', 'contact'];

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export const ZERO = 0;
export const OFFSET = 200;
export const formattedId = (page: string) =>
  page.toLowerCase().replace(' ', '-');

export const ImgsServices = [
  './fullServiceLaundry.png',
  './luggage.png',
  './cloverCleaningService.svg',
  './logoCloverBlack.svg',
];

export const ImgsLuggage = ['../smallLuggage.png', '../bigLuggage.png'];
export const ImgsFullService = [
  '../fullServiceLaundry.png',
  '../washMachine.png',
  '../tumbleDryer.png',
];

export const handleScrollTo = (targetTop: number) => {
  window.scrollTo({
    top: targetTop,
    behavior: 'instant' as ScrollBehavior,
  });
};

export enum SERVICE_INDEX {
  FULL_SERVICE = 0,
  LUGGAGE = 1,
  APT_CLEANING = 2,
  COMMERCIAL = 3,
}

export const getOffsetBySectionId = (sectionId: string) => {
  const section = document.getElementById(sectionId);
  const sectionPosition = section?.getBoundingClientRect().top || 0;
  const topSectionPosition = sectionPosition + window.scrollY - OFFSET;
  return topSectionPosition;
};
