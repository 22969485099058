import { palette } from './../utils/styleguide';
import { Parallax } from './../components/Parallax';
import ServicesSection from './../sections/ServicesSection';
import {
  CustomCurveShape,
  CustomShapeDividerOp,
} from './../components/WaveSeparator';
import { ParallaxBetweenSections } from './../styles/styles';
import FAQSection from './../sections/FAQuestionsSection';
import { useScrollToTop } from '../utils/hook';

const LandingPage = () => {
  useScrollToTop();
  return (
    <>
      <Parallax />
      <CustomShapeDividerOp shapeColor={palette.darkWhite} />
      <ServicesSection />

      <FAQSection />
      <ParallaxBetweenSections>
        <CustomCurveShape position="top" />
        <CustomCurveShape position="bottom" rotate />
      </ParallaxBetweenSections>
    </>
  );
};

export default LandingPage;
