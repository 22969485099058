import { Typography } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import { palette } from '../utils/styleguide';
import { useCloverNavigator } from '../utils/hook';
import { useTranslation } from 'react-i18next';

const ThankYouPageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: palette.skyBlue,
        width: '100%',
        height: '100%',
        paddingTop: 40,
        paddingBottom: 20,
      }}
    >
      {children}
    </Grid>
  );
};

const ThankYouPage = () => {
  const { t } = useTranslation();
  const { navigateHomePage } = useCloverNavigator();
  useEffect(() => {
    setTimeout(() => {
      navigateHomePage();
    }, 3000);
  }, [navigateHomePage]);

  return (
    <ThankYouPageWrapper>
      <Typography
        variant="h2"
        sx={{
          color: palette.white,
          textAlign: 'center',
          fontWeight: 'bold',
          borderRadius: '50px',
        }}
      >
        {t('thankYou.title')}
      </Typography>

      <Typography
        variant="h5"
        sx={{
          color: palette.white,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {t('thankYou.redirectMessage')}
      </Typography>
    </ThankYouPageWrapper>
  );
};

export default ThankYouPage;
