import { FmdGood, Instagram, WhatsApp } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { palette } from '../utils/styleguide';
import CallIcon from '@mui/icons-material/Call';

export const InstagramLink = () => {
  return (
    <Link
      href={'https://www.instagram.com/cloverlaundryalicante'}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Instagram
        sx={{ color: palette.darkBlue, fontSize: 40, cursor: 'pointer' }}
      />
    </Link>
  );
};

export const WhatsAppLink = () => {
  return (
    <Link target="_blank" rel="noopener noreferrer">
      <WhatsApp
        sx={{
          color: palette.darkBlue,
          marginLeft: 1,
          fontSize: 40,
          cursor: 'pointer',
        }}
      />
    </Link>
  );
};

export const LocationLink = () => {
  return (
    <Link
      href="https://www.google.com/maps/place/Clover+Laundry/@38.3468357,-0.4819998,17z/data=!3m1!4b1!4m6!3m5!1s0xd623724ea50c589:0xcdb8be07361eb478!8m2!3d38.3468357!4d-0.4819998!16s%2Fg%2F11wfy27b72?entry=ttu&g_ep=EgoyMDI0MTAxNC4wIKXMDSoASAFQAw%3D%3D"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FmdGood sx={{ color: palette.darkBlue, fontSize: 40 }} />
    </Link>
  );
};

export const TelephoneIcon = () => {
  return (
    <Link target="_blank" rel="noopener noreferrer">
      <CallIcon
        sx={{
          color: palette.darkBlue,
          marginLeft: 1,
          fontSize: 40,
          cursor: 'pointer',
        }}
      />
    </Link>
  );
};
