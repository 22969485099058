import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import Grid from '@mui/material/Grid2';
import { palette } from '../utils/styleguide';

const ErrorPageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.skyBlue,
        width: '100%',
        height: '60vh',
        margin: 0,
      }}
    >
      {children}
    </Grid>
  );
};

const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
      <Typography
        variant="h1"
        sx={{
          color: palette.white,
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: {
            xs: '2rem',
            sm: '3rem',
            md: '4rem',
            lg: '5rem',
          },
          border: '10px dotted',
          borderColor: palette.white,
          padding: {
            xs: '20px',
            sm: '50px',
            md: '100px',
          },
          borderRadius: '50px',
        }}
      >
        {'Page not found'}
      </Typography>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
