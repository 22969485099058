import styled from '@emotion/styled';
import { createTheme } from '@mui/material';

export const AppWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0052cc',
    },
    secondary: {
      main: '#edf2ff',
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    fontSize: 16,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const palette = {
  darkWhite: '#F8F9FC',
  white: '#ffffff',
  purple: '#6c348c',
  green: '#99bf3c',
  black: '#000000',
  darkBlue: '#11335c',
  skyBlue: '#72bce9',
  darkGreen: '#13a64e',
  grey: '#f9f9f9',
  blue: '#5f95de',
};

export const sectionStyle = {
  height: 'auto',
  transition: 'height 0.5s',
};
