import { ApartmentCleaningPage } from './ApartmentCleaningPage';
import FullServicePage from './FullServicePage';
import LuggagePage from './LuggagePage';
import ComercialLaundryPage from './ComercialLaundryPage';
import { useCloverNavigator } from '../../utils/hook';

const ServicesPage = () => {
  const { isFullService, isLuggage, isApartmentCleaning, isCommercialLaundry } =
    useCloverNavigator();

  return (
    <div>
      {isFullService && <FullServicePage />}
      {isLuggage && <LuggagePage />}
      {isApartmentCleaning && <ApartmentCleaningPage />}
      {isCommercialLaundry && <ComercialLaundryPage />}
    </div>
  );
};

export default ServicesPage;
