import { useTranslation } from 'react-i18next';
import { useScrollToTop } from '../../utils/hook';
import { SERVICE_INDEX } from '../../utils/utils';
import { palette } from '../../utils/styleguide';
import Grid from '@mui/material/Grid2';
import { ReactNode } from 'react';
import { Separator } from '../CommonComponents';
import Typography from '@mui/material/Typography';

export const CommercialLaundryWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Grid
      container
      justifyContent="center"
      paddingTop={2}
      paddingBottom={10}
      paddingLeft={{ sm: 5, xs: 2 }}
      paddingRight={{ sm: 5, xs: 2 }}
      spacing={4}
      direction="column"
      sx={{ marginBottom: 'auto', marginTop: '100px' }}
    >
      {children}
    </Grid>
  );
};

const CommercialPageContent = () => {
  const { t } = useTranslation();
  useScrollToTop();

  const sectionDescription = t(
    `services.${SERVICE_INDEX.COMMERCIAL}.sectionDescription`
  );
  const [firstSentence, ...remainingSentences] = sectionDescription.split('.');

  return (
    <Grid
      container
      sx={{
        padding: '40px',
        border: '4px dotted',
        borderRadius: '12px',
        maxWidth: '1270px',
        margin: '0 auto',
        borderColor: palette.skyBlue,
        backgroundColor: palette.grey,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid container justifyContent="center" marginBottom={3}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: palette.blue,
            fontSize: { xs: '2rem', sm: '3.3rem' },
            textTransform: 'uppercase',
            letterSpacing: '1px',
          }}
        >
          {t(`services.${SERVICE_INDEX.COMMERCIAL}.title`)}
        </Typography>
      </Grid>

      <Grid container spacing={4} alignItems="center">
        <Grid size={{ xs: 12, md: 6 }} display="flex" justifyContent="center">
          <img
            src={'../commercialLaundryImg.png'}
            alt="Commercial Laundry Service"
            style={{ width: '70%', maxWidth: '500px', height: 'auto' }}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography
            variant="body1"
            sx={{
              color: palette.darkBlue,
              fontSize: { xs: '1rem', sm: '1.3rem' },
              marginBottom: 2,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: { xs: '1.2rem', sm: '2rem' },
                display: 'block',
                marginBottom: 2,
                color: palette.skyBlue,
              }}
            >
              {firstSentence}.
            </Typography>
          </Typography>
        </Grid>
      </Grid>

      <Grid size={{ xs: 12 }}>
        {remainingSentences.slice(0, -1).map((sentence, index) => (
          <Typography sx={{ color: palette.darkBlue }} key={index}>
            {index % 2 === 0 ? (
              <strong style={{ fontSize: '1.2rem' }}>
                {sentence.trim() + '.'}
              </strong>
            ) : (
              <em>{sentence.trim() + '.'}</em>
            )}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export const CommercialLaundryPage = () => {
  return (
    <CommercialLaundryWrapper>
      <Separator />
      <CommercialPageContent />
    </CommercialLaundryWrapper>
  );
};

export default CommercialLaundryPage;
