import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { palette } from '../../utils/styleguide';
import { SERVICE_INDEX } from '../../utils/utils';
import { Separator } from '../CommonComponents';
import { useScrollToTop } from '../../utils/hook';
import Grid from '@mui/material/Grid2';
import { ReactNode } from 'react';

export const ApartmentCleaningWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Grid
      container
      justifyContent="center"
      paddingTop={2}
      paddingBottom={10}
      paddingLeft={{ sm: 5, xs: 3 }}
      paddingRight={{ sm: 5, xs: 3 }}
      spacing={4}
      direction="column"
      sx={{ marginBottom: 'auto', marginTop: '100px' }}
    >
      {children}
    </Grid>
  );
};

export const ApartmentCleaningContent = () => {
  const { t } = useTranslation();
  useScrollToTop();

  return (
    <Grid
      container
      sx={{
        padding: { xs: '10px', sm: '30px' },
        border: '4px dotted',
        borderRadius: '12px',
        maxWidth: '1270px',

        margin: '0 auto',
        borderColor: palette.skyBlue,
        backgroundColor: palette.grey,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid container justifyContent="center" marginBottom={3}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: palette.blue,
            marginBottom: 2,
            fontSize: { xs: '2rem', sm: '3.3rem' },
            textTransform: 'uppercase',
            letterSpacing: '1px',
          }}
        >
          {t('services.' + SERVICE_INDEX.APT_CLEANING + '.title')}
        </Typography>
      </Grid>

      <Grid container spacing={4} alignItems="center">
        <Grid size={{ xs: 12, xl: 6, md: 12, lg: 12 }}>
          <Typography
            variant="body1"
            sx={{
              color: palette.darkBlue,
              fontSize: { xs: '1rem', sm: '1.3rem' },

              marginTop: 6,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: { xs: '1.2rem', sm: '3.2rem' },
                display: 'block',
                marginBottom: 1,
                color: palette.skyBlue,
              }}
            >
              {
                t(
                  'services.' +
                    SERVICE_INDEX.APT_CLEANING +
                    '.sectionDescription'
                ).split('.')[0]
              }
              .
            </Typography>
            {t('services.' + SERVICE_INDEX.APT_CLEANING + '.sectionDescription')
              .split('.')
              .slice(1, -1)
              .join('. ') + '.'}
          </Typography>
        </Grid>

        <Grid
          size={{ xs: 12, xl: 6, md: 12, lg: 12 }}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <img
            src={'../cleaningServiceImg.png'}
            alt="apartment cleaning"
            style={{
              width: '70%',
              maxWidth: '500px',
              height: 'auto',
              marginTop: '20px',
            }}
          />
        </Grid>
        <Typography
          component="span"
          sx={{
            fontSize: { xs: '1.2rem', sm: '2.0rem' },
            display: 'block',
            marginTop: 2,
            color: palette.skyBlue,
          }}
        >
          {t('services.' + SERVICE_INDEX.APT_CLEANING + '.sectionDescription')
            .split('.')
            .slice(-1)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const ApartmentCleaningPage = () => {
  return (
    <ApartmentCleaningWrapper>
      <Separator />
      <ApartmentCleaningContent />
    </ApartmentCleaningWrapper>
  );
};
